import * as Sentry from '@sentry/browser';

export function initMonitoring() {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    release: import.meta.env.VITE_SENTRY_RELEASE,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

type User = {
  userId: string;
  accountId: string;
  domainId: string;
};

export function setMonitoringContext({ userId, accountId, domainId }: User) {
  Sentry.setUser({
    id: userId,
  });

  Sentry.setTags({
    userId,
    accountId,
    domainId,
  });
}

export function reportMonitoringException(e: unknown) {
  Sentry.captureException(e);
}
